import React, { useEffect, useState } from 'react'

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import moment from 'moment';
import { useLocation } from 'wouter';

const AdminPage = ({ user, selectedDeal, setSelectedDeal, setDetailModalOpen }) => {

	const [deals, setDeals] = useState([])
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true)

  // eslint-disable-next-line
  const [location, navigate] = useLocation()

  useEffect(() => {
    const fetchDeals = async () => {
      // Create a query against the collection, ordering by dateSubmitted
      const dealsQuery = query(collection(db, "deals"), orderBy('dateSubmitted', 'asc'));
      const querySnapshot = await getDocs(dealsQuery);

      const dealsData = querySnapshot.docs.reverse().map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Convert Timestamp to JavaScript Date object if necessary
        // dateSubmitted: doc.data().dateSubmitted.toDate()
      }));

      setDeals(dealsData);
      setLoading(false);
    };

    fetchDeals();
  }, [user, selectedDeal]);

	const handleViewDeal = (deal) => {
		setSelectedDeal(deal)
		setDetailModalOpen(true)
	}

	return (
		<div className='bg-themeColorPrimary3 min-h-full flex flex-col w-full px-6 pt-6'>
      <div className='mt-[200px] md:mt-[130px] lg:mt-[130px]'>
        <h1 className="text-4xl font-bold my-4 mb-[30px]">All Deals</h1>
        {deals.length > 0 ? (
          <table className="w-full px-6">
            <thead>
              <tr className="text-center text-xl">
                <th className="pb-4">Date Submitted</th>
                <th className="pb-4">ISO</th>
                <th className="pb-4">ISO Referrer</th>
                <th className="pb-4">Business Name</th>
                <th className="pb-4">Status</th>
              </tr>
            </thead>
            <tbody>
              {deals.map((deal) => (
                <tr
									key={deal.id}
									className="mb-4 shadow-lg rounded-2xl border w-full cursor-pointer hover:shadow-xl hover:border-themeColorPrimary transition-all"
								>
                  <td className="font-semibold text-base text-center mb-2 py-4">{moment((deal.dateSubmitted).toDate()).format('MM - DD - YYYY')}</td>
                  <td className="text-base text-center mb-2 py-4 cursor-pointer font-bold underline" onClick={() => handleViewDeal(deal)}>{deal.companyName}</td>
                  <td className="text-base text-center mb-2 py-4 cursor-pointer font-bold underline" onClick={() => handleViewDeal(deal)}>{deal.referrer}</td>
                  <td className="font-semibold text-base text-center mb-2 py-4">{deal.businessName}</td>
                  <td className="font-semibold text-base text-center mb-2 py-4">{deal.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          !loading &&
          <div>
            <table className="w-full px-6">
              <thead>
                <tr className="text-center text-xl">
                  <th className="pb-4">Date Submitted</th>
                  <th className="pb-4">ISO</th>
                  <th className="pb-4">ISO Referrer</th>
                  <th className="pb-4">Business Name</th>
                  <th className="pb-4">Status</th>
                </tr>
              </thead>
            </table>
            <p className="w-full text-center mt-4 text-2xl font-bold text-red-600">No deals found!</p>
          </div>
        )}
      </div>
    </div>
	)
}

export default AdminPage