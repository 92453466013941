import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import Routes from './Routes';
import Header from './components/Header';
import { useLocation } from 'wouter';
import { auth } from './config/firebaseConfig';
import Loading from './components/Loading';
import Footer from './components/Footer';
import { fetchUserInfo } from './utils/helpers';

const App = () => {
  const [location, navigate] = useLocation();
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [user, setUser] = useState(null)

  const VERSION = process.env.REACT_APP_VERSION

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false); // Set loading to false after check
      if (user) {
        if(user.email !== 'admin@quidity.net') {
          // If there is a user and we're at the root, redirect to the portal
          setUser(user)
          if (location === "/") {
            navigate('/portal')
          }
        } else {
          setUser(user)
          if (location === "/") {
            navigate('/admin')
          }
        }
      } else {
        // If there's no user, stay on the current page, unless we're at the portal, then redirect to root
        if (location === "/portal" || location === "/admin") {
          navigate('/');
        }
      }
    });
  
    // Cleanup subscription on unmount
    return () => {
      setLoading(true); // Reset the loading state when the component unmounts
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [navigate, location, auth]);

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (user) {
      fetchUserInfo(user)
			.then((userInfo) => setUserInfo(userInfo))

    }
  }, [user]);

  // Show loading component while checking authentication state
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col app bg-themeColorPrimary3 min-h-screen">
      <Header user={user} userInfo={userInfo}/>
      <Routes user={user} userInfo={userInfo}/>
      <Footer user={user}/>

      <div className='mt-auto ml-auto text-base font-semibold p-4 pr-6'>v{VERSION}</div>
    </div>
  );
}

export default App;