import React from 'react';
import Logo from '../assets/logo.png';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { useLocation } from 'wouter';

const Header = ({ user, userInfo }) => {
  const [location, navigate] = useLocation();

  const handleCreateDeal = () => {
    navigate('/create-deal');
  };

  const handlePortal = () => {
    navigate('/portal');
  };

  const handleLogout = () => {
    signOut(auth);
  };

  return (
		<>
		  {
        userInfo &&
        <div className='fixed mb-4 w-full h-[35px] bg-themeColorPrimary px-[50px] flex items-center'>
          <p className='w-fit ml-auto text-white font-semibold'>Hello, <b>{`${userInfo.firstName} ${userInfo.lastName}` || 'User'}</b> {`(${userInfo.companyName.toUpperCase()})`}</p>
        </div>
      }
			<div style={{ top: userInfo ? 35 : 0 }} className='fixed px-8 py-4 bg-white w-full flex flex-col md:flex-row lg:flex-row z-[999] shadow-sm'>
				<a href="/" className='w-fit'><img className='h-[60px] md:h-[65px] lg:h-[65px]' src={Logo} alt="logo" /></a>
				{
					location === '/portal' &&
					<div className='mt-4 md:mt-0 lg:mt-0 flex md:ml-auto lg:ml-auto items-center md:items-center ld:items-center flex-col md:flex-row lg:flex-row'>
						<button onClick={handleCreateDeal} className="bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded">
							Create Deal
						</button>
						<button onClick={handleLogout} className="ml-2 mt-2 md:mt-0 lg:mt-0 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded">
							Logout
						</button>
					</div>
				}
				{
					location === '/admin' &&
					<div className='mt-4 md:mt-0 lg:mt-0 flex md:ml-auto lg:ml-auto items-center md:items-center ld:items-center flex-col md:flex-row lg:flex-row'>
						<button onClick={handleLogout} className="ml-2 mt-2 md:mt-0 lg:mt-0 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit rounded">
							Logout
						</button>
					</div>
				}
				{
					location === '/create-deal' &&
					<button onClick={handlePortal} className="bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 h-fit ml-auto my-auto rounded">
						Portal
					</button>
				}
			</div>
		</>
  );
};

export default Header;
