import React from 'react';
import { IoMdCloseCircleOutline } from "react-icons/io";

const Modal = ({ onClose, children }) => {

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-[999] modal-animation">
      <div className="relative top-20 mx-auto px-3 py-2 border w-96 shadow-sm rounded-md bg-white">
        <div className="flex justify-between items-center mb-3">
          <button className="ml-auto"onClick={onClose}><IoMdCloseCircleOutline size={30} color='#078586' /></button>
        </div>
        <div className="mb-4">
          {children}
        </div>
        <div className="flex justify-end">
          {/* <button
            onClick={onClose}
            className="ml-auto mr-auto px-4 py-2 bg-themeColorPrimary hover:bg-themeColorPrimary2 transition-all text-white text-sm font-medium rounded-md"
          >
            Close
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
