import React from 'react';
import { useLocation } from 'wouter';

const Footer = ({ user }) => {
  const [location] = useLocation()

  return (
    (['/portal', '/create-deal'].includes(location) && user) &&
    <footer className="mt-[200px] bg-gray-200 text-gray-700 p-4 text-sm border-t-[8px] border-themeColorPrimary">
      <div className="max-w-6xl mx-auto">
        <div className="mb-6">
          <h5 className="font-bold mb-2">IMPORTANT STIP NOTES</h5>
          <p>Minimum Stipulation Guidelines for Quidity Line of Credit (LOC)</p>

          <h6 className="font-bold mt-4">Applicant Requirements:</h6>
          <ul className="list-inside list-disc">
            <li>US citizen or permanent resident</li>
            <li>Minimum FICO Equifax score of 640+</li>
            <li>Business in operation for at least 2 years</li>
            <li>Annual revenue of $360,000 or more</li>
            <li>Profitable business with positive DSCR</li>
          </ul>

          <h6 className="font-bold mt-4">Financial Requirements:</h6>
          <ul className="list-inside list-disc">
            <li>Minimum daily balance of $10,000 (guideline)</li>
            <li>Maximum of 5 NSFs per year</li>
            <li>Minimum monthly deposits of $30,000</li>
            <li>Maximum of 12 negative days per year</li>
            <li>Minimum monthly ending balance of $3,000</li>
          </ul>

          <h6 className="font-bold mt-4">Documentation:</h6>
          <ul className="list-inside list-disc">
            <li>An active bank connection or statements from the last 3 months (a connected account makes it faster and easier to confirm your information)</li>
          </ul>

          <h6 className="font-bold mt-4">Knockouts:</h6>
          <ul className="list-inside list-disc">
            <li>Bankruptcy</li>
            <li>Charge-offs greater than $2,500</li>
            <li>More than 2 funded MCAs in the last 12 months</li>
            <li>Businesses in restricted industries</li>
          </ul>

          <h6 className="font-bold mt-4">Loan Purpose:</h6>
          <ul className="list-inside list-disc">
            <li>Not designed to pay off MCAs over $50,000</li>
          </ul>

          <h6 className="font-bold mt-4">State Restrictions:</h6>
          <ul className="list-inside list-disc">
            <li>Not available in Nevada, North Dakota, South Dakota, Tennessee, Puerto Rico, Hawaii, New York, and California (license in final stages of approval)</li>
          </ul>

          <h6 className="font-bold mt-4">Restricted Industries:</h6>
          <ul className="list-inside list-disc">
            <li>Casinos (NAICS 713210)</li>
            <li>Other Gambling Industries (NAICS 713290)</li>
            <li>All Other Amusement and Recreation (NAICS 713990)</li>
            <li>Pharmacies and Drug Retailers (NAICS 446110)</li>
            <li>Small Arms Manufacturing (NAICS 332994)</li>
            <li>Money Transmission Services (NAICS 522390)</li>
            <li>Restaurants (NAICS 722511, 72251, 722513)</li>
            <li>Rooming and Boarding Houses (NAICS 721300)</li>
            <li>CBD (Cannabidiol) (NAICS 111419)</li>
            <li>All Non-Profit Organizations</li>
          </ul>

          <p className="mt-4">
            Please note that these are minimum stipulation guidelines, and meeting these requirements does not guarantee approval. All applications are subject to underwriting and credit approval. Terms and conditions may vary based on individual business qualifications and the lender’s discretion.
          </p>
        </div>
        
        <div className="mt-4 mb-6 font-bold">
          <p>For questions on your partner agreement or commissions, <br />
            please contact: <a href="mailto:aj@quidity.net" className="text-themeColorPrimary hover:underline">aj@quidity.net</a>
          </p>
        </div>
        <div className="text-center mt-2 md:mt-6 font-bold">
          <p>Quidity© 2024 All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
