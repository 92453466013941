// src/components/LoadingComponent.js
import React from 'react';

const Loading = ({ theme }) => {
  return (
    <div className="loading-container">
      <div className="loading-spinner" style={{ borderLeftColor: theme === 'primary' ? '#078586' : theme === 'secondary' ? '#282f3b' : '#000000' }}></div>
    </div>
  );
};

export default Loading;
