import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import moment from 'moment';

export const sendWelcomeEmail = async (email, name) => {
  try {
    const response = await axios.post('https://eof8x5y3avaii7r.m.pipedream.net', {
      email,
      name,
    });

    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const sendDealCreatedEmail = async (email, companyName, businessName, referrer, referrerEmail, ownerName) => {

  const emailSubject = `New submission: ${businessName}`
  const emailContent = `The following QBF partner ${companyName} has submitted the following for review\nCompany name: ${businessName}\nISO Contact Info: ${referrer}\nISO Email: ${referrerEmail}\nDate & Time: ${moment().format('MM/DD/YYYY')} - ${moment().format('hh:mm:ss')}`
  try {
    const response = await axios.post('https://eoxo91vhohh77xg.m.pipedream.net', {
      email,
      emailSubject,
      emailContent,
      name: ownerName,
      company: companyName,
    });

    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const sendDealCreatedText = async (companyName, businessName, referrer, referrerEmail) => {

  const textContent = `ISO NAME: ${companyName}\nCOMPANY NAME: ${businessName}\nISO CONTACT: ${referrer}\nISO EMAIL: ${referrerEmail}`

  try {
    const response = await axios.post('https://eo7s5dl3bjpb56h.m.pipedream.net', {
      textContent,
    });

    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const sendNewRegistrationEmail = async (company, address, city, state, zip, firstname, lastname, mobilephone, email) => {

  const emailSubject = `New ISO Registered - Action Required`
  const emailContent = `The following company has just enrolled in the Quidity Business Funding portal:\nCompany Name: ${company}\nAddress: ${address}\nCity: ${city}\nState: ${state}\nZip: ${zip}\nPrimary Contact Person: ${firstname} ${lastname}\nTelephone: ${mobilephone}\nContact Email: ${email}\nDate: ${moment().format('MM/DD/YYYY')}}\n\nPlease contact them at your earliest convenience to walk them through the process of submitting deals for your programs.\n\nBest regards,\nThe Quidity Business Funding Team `

  try {
    const response = await axios.post('https://eowhg0qaeoinlv5.m.pipedream.net', {
      emailSubject,
      emailContent,
    });

    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export const fetchUserInfo = async (user) => {
  try {
    const userRef = doc(db, 'users', user.uid); // Reference to the user document in Firestore
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      return (docSnap.data()); // Set the user info state with data from Firestore
    } else {
      console.log('No such document!');
      return null
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};