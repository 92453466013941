import React, { useState } from 'react';
import Modal from '../components/Modal';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { FiDownload } from 'react-icons/fi';
import { saveAs } from 'file-saver';
import axios from 'axios';

const DealDetailModal = ({ user, selectedDeal, onClose }) => {

  // const formatDate = (date) => moment(date).format('MMMM Do, YYYY');

  const [loadingFileDownload, setLoadingFileDownload] = useState(false)

   // Assuming you have a function to update the status in your backend or state
   const [dealStatus, setDealStatus] = useState(selectedDeal.status);

   const handleStatusChange = async (event) => {
     const newStatus = event.target.value;
     setDealStatus(newStatus);

     try {
      await setDoc(doc(db, 'deals', selectedDeal.id), { status: newStatus }, { merge: true })
      onClose()
    } catch (error) {
      console.error("Error updating status: ", error);
    }
   };

   const handleDownload = async (url) => {
    setLoadingFileDownload(true)
    try {
        const response = await axios.get(url, { responseType: 'blob' });

        // Extracting the filename from the URL
        const urlParts = new URL(url);
        let path = urlParts.pathname;
        // The pathname will include the initial '/', so split and get the last part
        let fileName = path.split('/').pop();

        // Decode URI component to handle URL-encoded filenames
        fileName = decodeURIComponent(fileName);

        // Initiating download with the file name
        saveAs(new Blob([response.data]), fileName);
        setLoadingFileDownload(false)
    } catch (error) {
        console.error('Download error:', error);
    }
};

  return (
    <>
      <Modal onClose={onClose}>
				<div className="mt-3 text-left">
        <h2 className="text-xl font-semibold mb-4">{selectedDeal.businessName}</h2>

        <section className="mb-4">
          <h2 className="text-lg underline font-bold mb-2">Business Information:</h2>
          <p><b>Referrer Name:</b> {selectedDeal.referrer}</p>
          <p><b>Referrer Email:</b> {selectedDeal.referrerEmail}</p>
          {/* <p><b>DBA:</b> {selectedDeal.dba}</p> */}
          <p><b>Industry:</b> {selectedDeal.industry}</p>
          <p><b>Description:</b> {selectedDeal.businessDescription}</p>
          <p><b>Address:</b> {selectedDeal.address}, {selectedDeal.city}, {selectedDeal.state} {selectedDeal.zip}</p>
          <p><b>Incorporated on:</b> {selectedDeal.dateIncorporated}</p>
          <p><b>{'MCA>$50k'}:</b> {selectedDeal.hasLargeLenderBalances}</p>
          <p><b>Use of funds:</b> {selectedDeal.useOfFunds || 'N/A'}</p>
        </section>

        <section className="mb-4">
          <h3 className="text-lg underline font-bold mb-2">Owner Information</h3>
          <p><b>Owner:</b> {selectedDeal.ownerName}</p>
          <p><b>Email:</b> {selectedDeal.ownerEmail}</p>
          <p><b>Date of Birth:</b> {selectedDeal.ownerDOB}</p>
          <p><b>SSN: </b>{selectedDeal.ownerSSN}</p>
          <p><b>Telephone:</b> {selectedDeal.ownerTel}</p>
          <p><b>Ownership:</b> {selectedDeal.pctOwnership}%</p>
        </section>

        <section className="mb-4">
          <h3 className="text-lg underline font-bold mb-2">Documents</h3>
          <ul>
          <li><p className='mb-2'>Click on each document to download:</p></li>
            <li>
                <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.bankStatement1, "Bank Statement 1")} disabled={loadingFileDownload}>
                    <FiDownload className="mr-2" />Bank Statement 1
                </button>
            </li>
            <li>
                <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.bankStatement2, "Bank Statement 2")} disabled={loadingFileDownload}>
                    <FiDownload className="mr-2" />Bank Statement 2
                </button>
            </li>
            <li>
                <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.bankStatement3, "Bank Statement 3")} disabled={loadingFileDownload}>
                    <FiDownload className="mr-2" />Bank Statement 3
                </button>
            </li>
            {
              selectedDeal.bankStatement4 && selectedDeal.bankStatement4 !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.bankStatement4, "Bank Statement 4")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Bank Statement 4
                  </button>
              </li>
            }
            <li>
                <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.businessApplication, "Business Application")} disabled={loadingFileDownload}>
                    <FiDownload className="mr-2" />Business Application
                </button>
            </li>
            {
              selectedDeal.driversLicenseFront && selectedDeal.driversLicenseFront !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.driversLicenseFront, "Drivers License Front")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Driver's License Front
                  </button>
              </li>
            }
            {
              selectedDeal.driversLicenseBack && selectedDeal.driversLicenseBack !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.driversLicenseBack, "Drivers License Back")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Driver's License Back
                  </button>
              </li>
            }
            {
              selectedDeal.voidedCheck && selectedDeal.voidedCheck !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.voidedCheck, "Voided Check")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Voided Check
                  </button>
              </li>
            }
            {
              selectedDeal.taxReturn1 && selectedDeal.taxReturn1 !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.taxReturn1, "Tax Return 1")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Business Tax Return 1
                  </button>
              </li>
            }
            {
              selectedDeal.taxReturn2 && selectedDeal.taxReturn2 !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.taxReturn2, "Tax Return 2")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Business Tax Return 2
                  </button>
              </li>
            }
            {
              selectedDeal.personalTaxReturn1 && selectedDeal.personalTaxReturn1 !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.personalTaxReturn1, "Personal Tax Return 1")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />Personal Tax Return 1
                  </button>
              </li>
            }
            {
              selectedDeal.microSbaApplicationUrl && selectedDeal.microSbaApplicationUrl !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.microSbaApplication, "MICRO SBA Application")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />MICRO SBA Application
                  </button>
              </li>
            }
            {
              selectedDeal.sbaExpressApplication && selectedDeal.sbaExpressApplication !== 'N/A'
              &&
              <li>
                  <button className='flex items-center font-semibold disabled:opacity-50 disabled:cursor-not-allowed' onClick={() => handleDownload(selectedDeal.sbaExpressApplication, "SBA Express Application")} disabled={loadingFileDownload}>
                      <FiDownload className="mr-2" />SBA Express Application
                  </button>
              </li>
            }
          </ul>
        </section>

        <section>
          <p><b>Agreed to Terms:</b> {selectedDeal.agreedToTerms ? 'Yes' : 'No'}</p>
          <br />
          <p>
            <b className='mr-2'>Status:</b>
            <select value={dealStatus} onChange={handleStatusChange} className="mt-1 cursor-pointer themed-input" disabled={!user?.email?.includes('admin')}>
              <option value="Submitted">Submitted</option>
              <option value="Additional info needed">Additional Info Needed</option>
              <option value="Awaiting Statements">Awaiting Statements</option>
              <option value="Contract Completed">Contract Completed</option>
              <option value="Declined">Declined</option>
              <option value="Declined for credit">Declined for Credit</option>
              <option value="Declined for industry">Declined for Industry</option>
              <option value="Declined for low balance">Declined for Low Balance</option>
              <option value="Declined for volume">Declined for Volume</option>
              <option value="Declined for recent funding">Declined for Recent Funding</option>
              <option value="Offer ready">Offer Ready</option>
              <option value="Offer Sent">Offer Sent</option>
              <option value="Plaid resent">Plaid Resent</option>
              <option value="Plaid Sent">Plaid Sent</option>
              <option value="Question for Broker">Question for Broker</option>
              <option value="Withdrawn">Withdrawn</option>
            </select>
          </p>
          </section>

          <div className="mt-6 flex justify-center">
            <a className='px-4 py-2 bg-themeColorPrimary text-white font-bold rounded' href={`/deal/modify/${selectedDeal.id}`}>Modify</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DealDetailModal;
