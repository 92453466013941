import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useState } from 'react';
import axios from 'axios';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { useLocation } from 'wouter';
import { sendDealCreatedEmail, sendDealCreatedText } from '../utils/helpers';

const initialStyles = {
  businessName: {},
  dba: {},
  referrer: {},
  referrerEmail: {},
  useOfFunds: {},
  address: {},
  city: {},
  state: {},
  zip: {},
  tel: {},
  dateIncorporated: {},
  industry: {},
  businessDescription: {},
  ownerName: {},
  ownerTel: {},
  ownerEmail: {},
  ownerDOB: {},
  ownerSSN: {},
  pctOwnership: {},
  driversLicenseFront: {},
  driversLicenseBack: {},
  bankStatement1: {},
  bankStatement2: {},
  bankStatement3: {},
  bankStatement4: {},
  voidedCheck: {},
  businessApplication: {},
  microSbaApplication: {},
  sbaExpressApplication: {},
};

const validStyle = { outline: '2px solid #078586' };
const invalidStyle = { outline: '2px solid red' };

const CreateDealPage = ({ user, userInfo }) => {
  
  const [formData, setFormData] = useState({
    referrer: '',
    referrerEmail: '',
    useOfFunds: '',
    businessName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    tel: '',
    dateIncorporated: '',
    industry: '',
    businessDescription: '',
    ownerName: '',
    ownerTel: '',
    ownerEmail: '',
    ownerDOB: '',
    ownerSSN: '',
    pctOwnership: '',
    driversLicenseFront: '',
    hasLargeLenderBalances: '',
    bankStatement1: '',
    bankStatement2: '',
    bankStatement3: '',
    bankStatement4: '',
    voidedCheck: '',
    businessApplication: '',
    microSbaApplication: '',
    sbaExpressApplication: '',
    agreedToTerms: false,
  })

  const storage = getStorage();

  const [loading, setLoading] = useState(false)
  const [submitStatusMessage, setSubmitStatusMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  // eslint-disable-next-line
  const [location, navigate] = useLocation();

  const industryCategories = {
    'Agriculture': [
      'None of the below',
      'Non-profit'
    ],
    'Automotive Repair': [
      'Body repair, collision, painting',
      'General repair, truck, rv',
      'Parts, accessories',
      'Quick service lube, tune-up',
      'Tire, glass',
      'Transmission',
      'Car wash',
      'Upholstery',
      'Non-profit'
    ],
    'Automobile Sales & Gas Services Stations': [
      'None of the below',
      'Automotive dealerships new/used',
      'Gas station/Convenience',
      'New & Used car dealers',
      'RV dealers',
      'Non-profit'
    ],
    'Business Services': [
      'None of the below',
      'Advertising sales',
      'Appraisal services',
      'Billing services',
      'Direct/Multi-level marketing',
      'Mail center',
      'Printing/graphic design',
      'Staffing/temp agencies',
      'Non-profit'
    ],
    'Communications': [
      'None of the below',
      'Non-profit'
    ],
    'Construction & Contractors': [
      'None of the below',
      'Construction contractors',
      'Electricians',
      'Flooring',
      'Heating/Air conditioning/HVAC',
      'House remodeling/improvements (ir. Structural remodeling)',
      'Housing related',
      'Painters',
      'Plumbers',
      'Roofing/siding',
      'Tile/carpet',
      'Windows',
      'Non-profit'
    ],
    'Eating & Drinking Places': [
      'None of the below',
      'Bar',
      'Catering',
      'Ice cream shops',
      'Restaurant - casual dining',
      'Restaurant - fast food & carry out',
      'Restaurant - fine dining',
      'Non-profit'
    ],
    'Educational Services': [
      'None of the below',
      'Daycare',
      'Schools',
      'Schools - educational training certifications',
      'Schools - private/elementary/high school',
      'Seminars',
      'Non-profit'
    ],
    'Entertainment Services': [
      'None of the below',
      'Adult entertainment',
      'Art galleries',
      'Casinos',
      'Cruise ships',
      'Entertainment venue',
      'Event planning',
      'Ticket brokers',
      'Travel agencies',
      'Non-profit'
    ],
    'Finance': [
      'None of the below',
      'Accounting',
      'Bail bonds',
      'Check cashing companies',
      'Collection agencies',
      'Consumer credit counseling services',
      'Payday loan companies',
      'Tax return preparation services',
      'Non-profit'
    ],
    'Food & Beverage Stores': [
      'None of the below',
      'Food- bakery',
      'Food - convenience & variety',
      'Food - deli, meat, seafood, other',
      'Food - grocery store',
      'Liquor store',
      'Non-profit'
    ],
    'Freight': [
      'None of the below',
      'Tow truck and yard',
      'Tractor trailer transportation',
      'Non-profit'
    ],
    'Health Services': [
      'None of the below',
      'Dental',
      'Hair replacements/clubs',
      'Health club',
      'Health club monthly membership',
      'Marijuana shop',
      'Medical services',
      'Pharmacy',
      'Spa Weightless centers',
      'Non-profit'
    ],
    'Hotels & Lodging': [
      'None of the below',
      'Hotel & motel',
      'Timeshares',
      'Non-profit'
    ],
    'Insurance': [
      'None of the below',
      'Insurance',
      'Warranty companies',
      'Non-profit'
    ],
    'Legal Services': [
      'None of the below',
      'Law firms',
      'Professional service (All doctors, lawyers)',
      'Non-profit'
    ],
    'Manufacturing': [
      'None of the below',
      'Non-profit'
    ],
    'Mining & Natural Resource Extraction': [
      'None of the below',
      'Non-profit'
    ],
    'Miscellaneous Services': [
      'None of the below',
      'Equipment rental',
      'Guard dog service',
      'Internet prescription',
      'Other service retail',
      'Non-profit'
    ],
    'Personal Services': [
      'None of the below',
      'Annual memberships',
      'Dry cleaners',
      'Hair & nail salon',
      'Interior design',
      'Lawn & garden',
      'Maid & cleaning services',
      'Online dating/memberships',
      'Pet washing & grooming',
      'Tanning & tattoo',
    

  'Non-profit'
    ],
    'Real Estate': [
      'None of the below',
      'Property management/real estate'
    ],
    'Religious Services': [
      'None of the below',
      'Churches',
      'Funeral homes',
      'Non-profit'
    ],
    'Retail': [
      'None of the below',
      'Antiques/collectibles',
      'Auctions houses',
      'Bridal shop',
      'Clothing & apparel',
      'Computer-related retail',
      'Ebay sales',
      'Electronics',
      'Firearms',
      'Flea market',
      'Florists',
      'Furniture',
      'Furriers',
      'Golf- stores, driving ranges, country clubs',
      'Heating oil',
      'Home appliance stores',
      'Home based door/door',
      'Home entertainment systems',
      'Internet sales (no storefront)',
      'Internet sales (storefront)',
      'Internet software downloads',
      'Jewelry',
      'Kiosks',
      'Mattresses',
      'Pet store',
      'Pools/spas',
      'Sporting goods',
      'Tobacco shop',
      'Wireless phone/accessories',
      'Other product retail',
      'Non-profit'
    ],
    'Storage': [
      'None of the below',
      'Boating/marina',
      'Non-profit'
    ],
    'Transportation': [
      'None of the below',
      'Airlines',
      'Daycare services - home based not allowed',
      'Day tours',
      'Limousine & transportation',
      'Non-profit'
    ],
    'Wholesale': [
      'None of the below',
      'Import/export',
      'Non-profit'
    ],
    'Vehicle Rental': [
      'None of the below',
      'Non-profit'
    ]
  }

  // eslint-disable-next-line
  const [categories, setCategories] = useState([]);

  const getPublicUrl = async (fileRef) => {
    try {
      const fileUrl = await getDownloadURL(ref(storage, fileRef));
      return fileUrl;
    } catch (error) {
      console.error('Error getting public URL:', error);
      setErrorMessage(error.message)
    }
  };

const uploadFileToFirebase = async (file) => {
  if (!file) return '';
  const storageRef = ref(storage, 'uploads/' + file.name);
  await uploadBytes(storageRef, file);
  return getPublicUrl(storageRef);
};

const handleChange = (e) => {
  const { name, value, files } = e.target;
  if (['bankStatement1', 'bankStatement2', 'bankStatement3', 'bankStatement4', 'driversLicenseFront', 'driversLicenseBack', 'voidedCheck', 'businessApplication', 'taxReturn1', 'taxReturn2', 'personalTaxReturn'].includes(name)) {
    // Handle file input
    setFormData(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  } else if (name === 'industry') {
    setFormData(prevState => ({
      ...prevState,
      industry: value
    }));
    setCategories(industryCategories[value] || [])
  } else if(name === 'ownerTel' || name === 'tel' || name === 'ownerSSN' || name === 'zip') {
    // Allow only numbers or an empty string to be set in the state
    const isIntegerOrEmpty = /^-?\d*$/.test(value)
    if(isIntegerOrEmpty) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }))
    }
  } else if (name === 'dateIncorporated' || name === 'ownerDOB') {
    const dateParts = value.split('-'); // Assuming the value is in 'YYYY-MM-DD' format
    if (dateParts[0].length > 4) { // If the year part is more than 4 characters
      // Correct the year part to the first 4 characters, or handle as needed
      dateParts[0] = dateParts[0].substr(0, 4);
      // Reassemble the corrected date
      const correctedValue = dateParts.join('-');
      // Update the form data with the corrected date
      setFormData({ ...formData, [name]: correctedValue });
    } else {
      // If the date is fine, update the form data as usual
      setFormData({ ...formData, [name]: value });
    }
  } else if (name === 'agreedToTerms') {
    setFormData({ ...formData, [name]: !formData.agreedToTerms })
  } else {
    // Handle other inputs
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
};

const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  // Test the input email against the pattern
  return emailPattern.test(email);
}


  const areAllFieldsFilled = () => {
    return validateEmail(formData.ownerEmail) &&
    formData.businessName &&
    formData.referrer &&
    validateEmail(formData.referrerEmail) &&
    formData.useOfFunds &&
    formData.address &&
    formData.city &&
    formData.state &&
    formData.zip &&
    formData.tel &&
    formData.dateIncorporated &&
    formData.industry &&
    formData.businessDescription &&
    formData.ownerName &&
    formData.ownerTel &&
    formData.ownerDOB &&
    formData.ownerSSN &&
    formData.pctOwnership &&
    formData.driversLicenseFront &&
    formData.hasLargeLenderBalances &&
    formData.bankStatement1 &&
    formData.bankStatement2 &&
    formData.bankStatement3
  }

  const [fieldStyles, setFieldStyles] = useState(initialStyles);

  const submitForm = async () => {

    setSubmitStatusMessage('Uploading documents...')

    setLoading(true)

    const driversLicenseFrontUrl = await uploadFileToFirebase(formData.driversLicenseFront);
    const bankStatement1Url = await uploadFileToFirebase(formData.bankStatement1);
    const bankStatement2Url = await uploadFileToFirebase(formData.bankStatement2);
    const bankStatement3Url = await uploadFileToFirebase(formData.bankStatement3);
    const voidedCheckUrl = formData.voidedCheck ? await uploadFileToFirebase(formData.voidedCheck) : 'N/A'
    const businessApplicationUrl = await uploadFileToFirebase(formData.businessApplication);

    const driversLicenseBackUrl = formData.driversLicenseBack ? await uploadFileToFirebase(formData.driversLicenseBack) : 'N/A'
    const bankStatement4Url = formData.bankStatement4 ? await uploadFileToFirebase(formData.bankStatement4) : 'N/A'
    const taxReturn1Url = formData.taxReturn1 ? await uploadFileToFirebase(formData.businessApplication) : 'N/A'
    const taxReturn2Url = formData.taxReturn2 ? await uploadFileToFirebase(formData.businessApplication) : 'N/A'
    const personalTaxReturn1Url = formData.personalTaxReturn1 ? await uploadFileToFirebase(formData.businessApplication) : 'N/A'
    const microSbaApplicationUrl = formData.microSbaApplication ? await uploadFileToFirebase(formData.microSbaApplication) : 'N/A'
    const sbaExpressApplicationUrl = formData.sbaExpressApplication ? await uploadFileToFirebase(formData.sbaExpressApplication) : 'N/A'

    setSubmitStatusMessage('Submitting records...')

    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/45548688/d5bb6158-feda-4b49-aa72-9dd65307cc7e`

    var name = formData.ownerName.split(' ')
		const firstName = name[0] || ''
		const lastName = name[1] || ''
    
		const hubspotFormData = {
      fields: [
        {
          name: "email",
          value: user.email,
        },
        {
          name: "company_size",
          value: formData.businessName,
        },
        // {
        //   name: "dba",
        //   value: formData.dba || 'N/A',
        // },
        {
          name: "message",
          value: formData.referrer,
        },
        {
          name: "work_email",
          value: formData.referrerEmail,
        },
        {
          name: "amount",
          value: formData.useOfFunds,
        },
        {
          name: "address2",
          value: formData.address,
        },
        {
          name: "domain",
          value: formData.city,
        },
        {
          name: "country",
          value: formData.state,
        },
        {
          name: "fax",
          value: formData.zip,
        },
        {
          name: "hs_whatsapp_phone_number",
          value: formData.tel,
        },
        {
          name: "date_incorporated",
          value: formData.dateIncorporated,
        },
        {
          name: "industry",
          value: formData.industry,
        },
        {
          name: "description",
          value: formData.businessDescription,
        },
        {
          name: "jobtitle",
          value: `${firstName} ${lastName}`,
        },
        {
          name: "mobilephone",
          value: formData.ownerTel,
        },
        {
          name: "website",
          value: formData.ownerEmail,
        },
        {
          name: "date_of_birth",
          value: formData.ownerDOB,
        },
        {
          name: "ssn",
          value: formData.ownerSSN,
        },
        {
          name: "pct_ownership",
          value: formData.pctOwnership,
        },
        {
          name: "relationship_status",
          value: formData.hasLargeLenderBalances,
        },
        {
          name: "bank_statement_1",
          value: `BANK STATEMENT 1: ${bankStatement1Url}\nBANK STATEMENT 2: ${bankStatement2Url}\nBANK STATEMENT 3: ${bankStatement3Url}`,
        },
        {
          name: "drivers_license",
          value: `FRONT: ${driversLicenseFrontUrl}`,
        },
        {
          name: "annualrevenue",
          value: voidedCheckUrl,
        },
        {
          name: "field_of_study",
          value: businessApplicationUrl,
        },
        // {
        //   name: "salutation",
        //   value: formData.agreedToTerms ? 'yes' : 'no',
        // },
        {
          name: "seniority",
          value: formData.corp || 'N/A',
        },
        {
          name: "school",
          value: `Tax Return 1: ${taxReturn1Url}\nTax Return 2: ${taxReturn2Url}\nPersonal Tax Return 1: ${personalTaxReturn1Url}`,
        }
      ],
    }

    try {
      await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)

      return {            
        driversLicenseFrontUrl,
        driversLicenseBackUrl,
        bankStatement1Url,
        bankStatement2Url,
        bankStatement3Url,
        bankStatement4Url,
        voidedCheckUrl,
        businessApplicationUrl,
        taxReturn1Url,
        taxReturn2Url,
        personalTaxReturn1Url,
        microSbaApplicationUrl,
        sbaExpressApplicationUrl,
      }
    } catch (error) {
      console.error('Network error:', error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(!areAllFieldsFilled()) {
      return
    }

    try {
      return submitForm()
      .then(async({            
        driversLicenseFrontUrl,
        driversLicenseBackUrl,
        bankStatement1Url,
        bankStatement2Url,
        bankStatement3Url,
        bankStatement4Url,
        voidedCheckUrl,
        businessApplicationUrl,
        taxReturn1Url,
        taxReturn2Url,
        personalTaxReturn1Url,
        microSbaApplicationUrl,
        sbaExpressApplicationUrl,
      }) => {
  
        setSubmitStatusMessage('Creating deal...')
  
        
        // Generate a new document reference with a random ID in the "deals" collection
        const dealRef = doc(collection(db, 'deals'));
  
        // Construct the new document data, including the userId field set to the user's email
        const newDeal = {
          ...formData,
          userId: user.email,
          driversLicenseFront: driversLicenseFrontUrl,
          driversLicenseBack: driversLicenseBackUrl,
          bankStatement1: bankStatement1Url,
          bankStatement2: bankStatement2Url,
          bankStatement3: bankStatement3Url,
          bankStatement4: bankStatement4Url,
          voidedCheck: voidedCheckUrl,
          taxReturn1: taxReturn1Url,
          taxReturn2: taxReturn2Url,
          personalTaxReturn1: personalTaxReturn1Url,
          businessApplication: businessApplicationUrl,
          dateSubmitted: serverTimestamp(),
          status: 'Submitted',
          referrer: `${userInfo?.firstName} ${userInfo?.lastName}`,
          companyName: userInfo?.companyName,
          microSbaApplication: microSbaApplicationUrl,
          sbaExpressApplication: sbaExpressApplicationUrl,
        };
  
        // Save the new deal document in Firestore
        await setDoc(dealRef, newDeal);
  
        await sendDealCreatedEmail(user.email, userInfo.companyName, formData.businessName, formData.referrer, formData.referrerEmail, formData.ownerName)
        await sendDealCreatedText(userInfo.companyName, formData.businessName, formData.referrer, formData.referrerEmail)
        
        setSubmitStatusMessage('Done...')
        setLoading(false)
        navigate('/portal')
      })
    }
    catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
      setLoading(false)
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
  
    let isValid = false; // Assume validity to start
  
    switch (name) {
      case 'ownerEmail':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValid = emailRegex.test(value);
        break;
      case 'zip':
        isValid = value.length===5
        break;
      case 'tel':
      case 'ownerTel':
        isValid = value.length===10
        break;
      case 'businessName':
      case 'referrer':
      case 'referrerEmail':
      case 'useOfFunds':
      case 'address':
      case 'city':
      case 'state':
      case 'dateIncorporated':
      case 'industry':
      case 'businessDescription':
      case 'ownerName':
      case 'ownerDOB':
      case 'ownerSSN':
      case 'pctOwnership':
      case 'driversLicenseFront':
      case 'bankStatement1':
      case 'bankStatement2':
      case 'bankStatement3':
      case 'businessApplication':
        // For these fields, check that the value is not empty. If it is, set isValid to false.
        isValid = value.trim() !== '';
        break;
      default:
        // For any fields not explicitly handled, assume they are valid
        isValid = true;
    }
  
    setFieldStyles(prevStyles => ({
      ...prevStyles,
      [name]: isValid ? validStyle : invalidStyle
    }));
  };

  return (
    user &&
    <div className="bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center" style={{ animation: 'bottom-top 500ms ease' }}>
      <div className="mt-[200px] md:mt-[150px] lg:mt-[150px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 w-[95%] md:w-[800px] lg:w-[800px] mb-[50px]">
        <h1 className="block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2">Create Deal</h1>
        <form onSubmit={handleSubmit}>
        <p className='text-lg font-medium mb-2'>Who should we contact regarding this merchant?</p>
        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Referrer Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
                Referrer *
              </label>
              <input
                style={fieldStyles.referrer}
                onBlur={handleBlur}
                type="text"
                id="referrer"
                name="referrer"
                value={formData.referrer}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
            {/* Referrer Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
                Referrer Email *
              </label>
              <input
                style={fieldStyles.referrerEmail}
                onBlur={handleBlur}
                type="text"
                id="referrerEmail"
                name="referrerEmail"
                value={formData.referrerEmail}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>
          </div>

          {/* Fields like in RegistrationPage */}
          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Business Name Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessName">
                Business Name *
              </label>
              <input
                style={fieldStyles.businessName}
                onBlur={handleBlur}
                type="text"
                id="businessName"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            {/* DBA Field */}
            {/* <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dba">
                DBA (Doing Business As)
              </label>
              <input
                style={fieldStyles.dba}
                onBlur={handleBlur}
                type="text"
                id="dba"
                name="dba"
                value={formData.dba}
                onChange={handleChange}
                className="themed-input"
              />
            </div> */}
          </div>

          {/* Address Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
              Company Address *
            </label>
            <input
              style={fieldStyles.address}
              onBlur={handleBlur}
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

          {/* City, State, Zip Code Fields */}
          <div className="grid grid-cols-3 gap-0 md:gap-4 lg:gap-4 mb-4">
            <input
              style={fieldStyles.city}
              onBlur={handleBlur}
              type="text"
              placeholder="City *"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
            <select
              style={fieldStyles.state}
              onBlur={handleBlur}
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input cursor-pointer"
              required
            >
              <option value="">State *</option>
								<option value="AL">AL</option>
								<option value="AK">AK</option>
								<option value="AZ">AZ</option>
								<option value="AR">AR</option>
								<option value="CA">CA</option>
								<option value="CO">CO</option>
								<option value="CT">CT</option>
								<option value="DE">DE</option>
								<option value="FL">FL</option>
								<option value="GA">GA</option>
								<option value="HI">HI</option>
								<option value="ID">ID</option>
								<option value="IL">IL</option>
								<option value="IN">IN</option>
								<option value="IA">IA</option>
								<option value="KS">KS</option>
								<option value="KY">KY</option>
								<option value="LA">LA</option>
								<option value="ME">ME</option>
								<option value="MD">MD</option>
								<option value="MA">MA</option>
								<option value="MI">MI</option>
								<option value="MN">MN</option>
								<option value="MS">MS</option>
								<option value="MO">MO</option>
								<option value="MT">MT</option>
								<option value="NE">NE</option>
								<option value="NV">NV</option>
								<option value="NH">NH</option>
								<option value="NJ">NJ</option>
								<option value="NM">NM</option>
								<option value="NY">NY</option>
								<option value="NC">NC</option>
								<option value="ND">ND</option>
								<option value="OH">OH</option>
								<option value="OK">OK</option>
								<option value="OR">OR</option>
								<option value="PA">PA</option>
								<option value="RI">RI</option>
								<option value="SC">SC</option>
								<option value="SD">SD</option>
								<option value="TN">TN</option>
								<option value="TX">TX</option>
								<option value="UT">UT</option>
								<option value="VT">VT</option>
								<option value="VA">VA</option>
								<option value="WA">WA</option>
								<option value="WV">WV</option>
								<option value="WI">WI</option>
								<option value="WY">WY</option>
            </select>
            <input
              style={fieldStyles.zip}
              onBlur={handleBlur}
              maxLength={5}
              type="text"
              placeholder="Zip Code *"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              className="input input-bordered w-full col-span-1 themed-input"
              required
            />
          </div>

          <div className='flex items-center flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
            {/* Telephone Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="tel">
                Telephone *
              </label>
              <input
                style={fieldStyles.tel}
                onBlur={handleBlur}
                type="text"
                maxLength={10}
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div>

            {/* EIN Field */}
            {/* <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ein">
                EIN
              </label>
              <input
                style={fieldStyles.ein}
                onBlur={handleBlur}
                type="text"
                id="ein"
                name="ein"
                value={formData.ein}
                onChange={handleChange}
                className="themed-input"
                required
              />
            </div> */}

            {/* Date Incorporated Field */}
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dateIncorporated">
                Date Incorporated *
              </label>
              <input
                style={fieldStyles.dateIncorporated}
                onBlur={handleBlur}
                type="date"
                id="dateIncorporated"
                name="dateIncorporated"
                value={formData.dateIncorporated}
                onChange={handleChange}
                className="themed-input"
              />
            </div>
          </div>

          {/* Business Category Select */}
          <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="businessCategory">
            Business Description *
          </label>
          <textarea
            maxLength={250}
            style={fieldStyles.businessDescription}
            onBlur={handleBlur}
            id="businessDescription"
            name="businessDescription"
            value={formData.businessDescription}
            onChange={handleChange}
            className="themed-input"
          />
        </div>


          {/* Use of Funds Field
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
            Corp 
            </label>
            <input
              style={fieldStyles.corp}
              onBlur={handleBlur}
              type="text"
              id="corp"
              name="corp"
              value={formData.corp}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div> */}

          {/* Use of Funds Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="address">
            Use of Funds *
            </label>
            <input
              style={fieldStyles.useOfFunds}
              onBlur={handleBlur}
              type="text"
              id="useOfFunds"
              name="useOfFunds"
              value={formData.useOfFunds}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>

        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
          {/* Industry Select */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="industry">
              Industry *
            </label>
            <select
              style={fieldStyles.industry}
              onBlur={handleBlur}
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="themed-input cursor-pointer"
            >
              <option value="">Select Industry</option>
              {Object.keys(industryCategories).map((ind) => (
                <option key={ind} value={ind}>{ind}</option>
              ))}
            </select>
          </div>

          {/* Business Owner Telephone Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerTel">
              Business Owner Telephone *
            </label>
            <input
              style={fieldStyles.ownerTel}
              onBlur={handleBlur}
              type="text"
              maxLength={10}
              id="ownerTel"
              name="ownerTel"
              value={formData.ownerTel}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>

          {/* Business Owner Name Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerName">
              Business Owner Name *
            </label>
            <input
              style={fieldStyles.ownerName}
              onBlur={handleBlur}
              type="text"
              id="ownerName"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              className="themed-input"
              required
              />
          </div>
          
          {/* Business Owner Email Field */}
          <div className="mb-4 w-full">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerEmail">
              Business Owner Email *
            </label>
            <input
              style={fieldStyles.ownerEmail}
              onBlur={handleBlur}
              type="email"
              id="ownerEmail"
              name="ownerEmail"
              value={formData.ownerEmail}
              onChange={handleChange}
              className="themed-input"
              required
            />
          </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Business Owner Date of Birth Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerDOB">
            Business Owner DOB *
          </label>
          <input
            style={fieldStyles.ownerDOB}
            onBlur={handleBlur}
            type="date"
            id="ownerDOB"
            name="ownerDOB"
            value={formData.ownerDOB}
            onChange={handleChange}
            className="themed-input"
          />
        </div>
    
        {/* Business Owner SSN Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="ownerSSN">
            Business Owner SSN *
          </label>
          <input
            style={fieldStyles.ownerSSN}
            onBlur={handleBlur}
            type="text"
            maxLength={9}
            id="ownerSSN"
            name="ownerSSN"
            value={formData.ownerSSN}
            onChange={handleChange}
            className="themed-input"
            required
          />
        </div>
    
        {/* Percentage Ownership Field */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="pctOwnership">
            Percentage Ownership *
          </label>
          <select
            style={fieldStyles.pctOwnership}
            onBlur={handleBlur}
            id="pctOwnership"
            name="pctOwnership"
            value={formData.pctOwnership}
            onChange={handleChange}
            className="themed-input cursor-pointer"
            required
          >
            <option value="">Select Percentage</option>
            <option value="10">10%</option>
            <option value="20">20%</option>
            <option value="30">30%</option>
            <option value="40">40%</option>
            <option value="50">50%</option>
            <option value="60">60%</option>
            <option value="70">70%</option>
            <option value="80">80%</option>
            <option value="90">90%</option>
            <option value="100">100%</option>
          </select>
        </div>
      </div>

      <div className="mt-4 mb-4 w-full">
        <label className="block text-grey-darker text-sm font-bold mb-2">
          Does applicant have existing lender balances over $50,000?
        </label>
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="hasLargeLenderBalances"
              value="yes"
              checked={formData.hasLargeLenderBalances === 'yes'}
              onChange={handleChange}
              className='h-[50px] w-[20px] cursor-pointer'
            />
            <span className="text-[14px] font-semibold">Yes</span>
          </label>
          <label className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              name="hasLargeLenderBalances"
              value="no"
              checked={formData.hasLargeLenderBalances === 'no'}
              onChange={handleChange}
              className='h-[50px] w-[20px] cursor-pointer'
            />
            <span className="text-[14px] font-semibold">No</span>
          </label>
        </div>
      </div>
    
      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        {/* Bank Statement */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 1 *
          </label>
          <input
            style={fieldStyles.bankStatement1}
            onBlur={handleBlur}
            type="file"
            id="bankStatement1"
            name="bankStatement1"
            onChange={handleChange}
            className="themed-input"
            />
            
        </div>
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 2 *
          </label>
          <input
            style={fieldStyles.bankStatement2}
            onBlur={handleBlur}
            type="file"
            id="bankStatement2"
            name="bankStatement2"
            onChange={handleChange}
            className="themed-input"
            />
        </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 3 *
          </label>
          <input
            style={fieldStyles.bankStatement3}
            onBlur={handleBlur}
            type="file"
            id="bankStatement3"
            name="bankStatement3"
            onChange={handleChange}
            className="themed-input"
          />
        </div>

        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Bank Statement 4 *
          </label>
          <input
            style={fieldStyles.bankStatement4}
            onBlur={handleBlur}
            type="file"
            id="bankStatement4"
            name="bankStatement4"
            onChange={handleChange}
            className="themed-input"
          />
        </div>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>

        {/* Drivers License */}
        <div className="mb-4 w-full">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
            Driver's License Front *
          </label>
          <input
            style={fieldStyles.driversLicenseFront}
            onBlur={handleBlur}
            type="file"
            id="driversLicenseFront"
            name="driversLicenseFront"
            onChange={handleChange}
            className="themed-input"
            />
            
            </div>
            <div className="mb-4 w-full">
              <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dlFront">
                Driver's License Back 
              </label>
              <input
                style={fieldStyles.driversLicenseBack}
                onBlur={handleBlur}
                type="file"
                id="driversLicenseBack"
                name="driversLicenseBack"
                onChange={handleChange}
                className="themed-input"
                />
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
        
            
              {/* Voided Check Upload */}
              {/* <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                  Voided Check 
                </label>
                <input
                  style={fieldStyles.voidedCheck}
                  onBlur={handleBlur}
                  type="file"
                  id="voidedCheck"
                  name="voidedCheck"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div> */}

              {/* Business Application Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                  Business Application *
                </label>
                <input
                  style={fieldStyles.businessApplication}
                  onBlur={handleBlur}
                  type="file"
                  id="businessApplication"
                  name="businessApplication"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>

              {/* Personal tax returns 1 Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                Personal Tax Return 1 
                </label>
                <input
                  style={fieldStyles.personalTaxReturn1}
                  onBlur={handleBlur}
                  type="file"
                  id="personalTaxReturn1"
                  name="personalTaxReturn1"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
              {/* Tax Return 1 Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                Business Tax Return 1 
                </label>
                <input
                  style={fieldStyles.taxReturn1}
                  onBlur={handleBlur}
                  type="file"
                  id="taxReturn1"
                  name="taxReturn1"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>

              {/* Tax Return 2 Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                Business Tax Return 2 
                </label>
                <input
                  style={fieldStyles.taxReturn2}
                  onBlur={handleBlur}
                  type="file"
                  id="taxReturn2"
                  name="taxReturn2"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
              {/* MICRO SBA Application Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                MICRO SBA Application 
                </label>
                <input
                  style={fieldStyles.microSbaApplication}
                  onBlur={handleBlur}
                  type="file"
                  id="microSbaApplication"
                  name="microSbaApplication"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>

              {/* SBA Express Application Upload */}
              <div className="mb-4 w-full">
                <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="voidedCheck">
                SBA Express Application 
                </label>
                <input
                  style={fieldStyles.sbaExpressApplication}
                  onBlur={handleBlur}
                  type="file"
                  id="sbaExpressApplication"
                  name="sbaExpressApplication"
                  onChange={handleChange}
                  className="themed-input"
                />
              </div>
          </div>
            

          {/* <div className="mb-4 w-full">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="agreedToTerms"
                id="agreedToTerms"
                checked={formData.agreedToTerms}
                onChange={handleChange}
                className="h-[50px] w-[20px]"
              />
              <span className="ml-2 text-base mb-[3px]">I agree to the <b>Terms and Conditions</b>.</span>
            </label>
          </div> */}

          {/* Submit Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[80%] disabled:hover:bg-themeColorPrimary"
              disabled={!areAllFieldsFilled() || loading}
            >
              {!loading ? "Create Deal" : <div className='flex items-center'><div className="button-loader mr-2"></div>Please wait</div>}

            </button>
          </div>
          <p className="mt-4 font-bold text-themeColorPrimary2">{loading && submitStatusMessage}</p>
        </form>
        <p className='mt-4 font-bold text-red-500'>
          {errorMessage}
        </p>
      </div>
    </div>
  );
};

export default CreateDealPage;
